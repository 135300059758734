import React from 'react';
import { injectIntl } from 'react-intl'

function Copyright({intl}) {

    return (
        <div className="copy">{intl.messages.copyright_text}</div>
    );
}


export default injectIntl(Copyright);



