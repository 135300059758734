export const ErrorType = {
    //Account Verification
    EMAIL_NOT_VERIFIED: 35,
    MOBILE_NOT_VERIFIED: 36,
    WRONG_AUTH_TOKEN: 37,
    EMAIL_AND_MOBILE_NOT_VERIFIED: 2016,
    SITE_NAME_AlREADY_EXISTS: 2021,
    COMPANY_NAME_AlREADY_EXISTS: 2057,
    JUST_VERIFIED: -1,
    VERIFIED_NOT_LOGGEDIN: 0,
    WRONG_CURRENT_PASSWORD: 2124,
    AUTH_ERROR: 401,
    PERMISSION_ERROR: 63,
    PROJECT_NOT_FOUND: 2044
};

export default ErrorType;